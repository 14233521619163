import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import profile_pic from "../../Assets/user.png";
import Logo from "../../Assets/logo.png";
import { useEffect } from "react";
import {
  fetchBranch,
  singleManager,
} from "../../FetchDataMethods/data";

const ManagerDetails = () => {
  const navigate = useNavigate();
  const [manager, setManager] = useState([]);
  const [branch, setBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const filteredBranch = branch.filter(
    (item) => item._id === manager?.organizationId
  );

  useEffect(() => {
    singleManagerData(id);
    fetchBranchData();
  }, []);

  const singleManagerData = async (id) => {
    setIsLoading(true);
    const data = await singleManager(id);
    setManager(data);
    setIsLoading(false);
  };

  const fetchBranchData = async () => {
    setIsLoading(true);
    const data = await fetchBranch();
    setBranch(data);
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex items-center">
        <IoMdArrowRoundBack
          size={25}
          className="mt-3"
          onClick={() => navigate("/superadmin/managers")}
        />
        <div className="font-bold text-2xl mt-4 tracking-wide w-full ml-2">
          Manager
        </div>
      </div>

      {isLoading ? (
        // <div className="animate-pulse bg-skeletonLoaderColor rounded-md h-24 w-24 rounded-full mb-4" />
        <div className="flex flex-col md:flex-row bg-white rounded-md p-4 gap-4">
          <div className="flex flex-col justify-center items-center md:w-[45%] p-8 rounded-lg border border-gray-400">
            <div className="animate-pulse bg-skeletonLoaderColor h-24 w-24 rounded-full mb-4"></div>
            <div className="h-3 animate-pulse bg-skeletonLoaderColor rounded-md mb-2 w-1/2"></div>
            <div className="h-2 animate-pulse bg-skeletonLoaderColor rounded-md mb-2 w-1/5"></div>
          </div>

          <div className="flex flex-col justify-center md:w-[55%] p-4 rounded-lg border border-gray-400">
            <h2 className="h-3 animate-pulse bg-skeletonLoaderColor rounded-md w-1/5 mb-4"></h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 text-gray-700">
              <div className="flex flex-col mb-2 lg:mb-0">
                <p className="mb-4 h-2 w-1/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
              </div>
              <div className="flex flex-col">
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row bg-white rounded-md p-4 gap-4 mt-4">
          <div className="flex flex-col justify-center items-center md:w-[45%] p-8 rounded-lg border border-gray-400">
            <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-400 mb-4">
              <img
                src={profile_pic}
                alt="Profile Picture"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-semibold">
                {manager?.userId?.firstName + " " + manager?.userId?.lastName}
              </h2>
              <p className="text-md text-gray-500">{manager?.userId?.role}</p>
            </div>
          </div>

          <div className="flex flex-col justify-center md:w-[55%] p-4 rounded-lg border border-gray-400">
            <h2 className="text-xl font-semibold mb-2 text-center md:text-left">
              About
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-700">
              <div className="flex flex-col mb-2 lg:mb-0">
                <p className="mb-2">Gender: {manager?.gender}</p>
                <p className="mb-2">Email: {manager?.userId?.email}</p>
                <p>Phone: {manager?.userId?.phone}</p>
              </div>
              <div className="flex flex-col">
                <p className="mb-2">Status: {manager?.status}</p>
                <p>State: {manager?.state}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {filteredBranch?.map((v, i) => {
        return (
          <div className="flex flex-wrap  mt-4">
            <div
              key={i}
              className="border w-72 h-60 border-gray-200 bg-[#fff] shadow-lg rounded-md flex flex-col justify-center items-center  p-3 gap-2  ease-in-out duration-300  hover:scale-[1.05] "
            >
              <div className="w-full flex justify-center items-center ">
                <img src={Logo} alt="logo" className="w-28 h-28" />
              </div>

              <div
                onClick={() => navigate(`/superadmin/manager-branch-details/${v?._id}`)}
                className="flex flex-col justify-center items-center cursor-pointer"
              >
                <div className="w-full flex items-center justify-center text-lg font-semibold text-primaryColor underline cursor-pointer capitalize">
                  {v?.name}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      
    </div>
  );
};

export default ManagerDetails;
