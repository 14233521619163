import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchBranch,
  fetchManager,
  fetchStaff,
} from "../../FetchDataMethods/data";
import axios from "axios";
import AddSubscription from "../../Components/SuperAdmin/Branch/BranchModal/AddSubscription";

const BranchDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [branch, setBranch] = useState([]);
  const [staff, setStaff] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const results = await Promise.allSettled([
        fetchBranch(),
        fetchStaff(),
        fetchManager()
      ]);

      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          switch (index) {
            case 0:
              setBranch(result.value.filter((item) => item?._id === id));
              break;
            case 1:
              setStaff(result.value.filter((item) => item?.organizationId === id));
              break;
            case 2:
              setManagers(result.value.find((item) => item?.organizationId === id));
              break;
            default:
              break;
          }
        } else {
          console.error('Error fetching data:', result.reason);
        }
      });
      setIsLoading(false);
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleStatusChange = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/organization/update/${id}`, {
        status: branch?.[0]?.status === 'active' ? 'inactive' : 'active',
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("superAdminrefresh_token")
        }
      });
      if (response?.data?.success) {
        setBranch([{ ...branch[0], status: response?.data?.data?.updatedOrganization?.status }]);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-between items-center my-4">
        <div className="flex items-center">
          <IoMdArrowRoundBack
            size={25}
            className=""
            onClick={() => navigate("/superadmin/admins")}
          />
          <div className="font-bold text-2xl tracking-wide w-full ml-2">
            Branch Overview
          </div>
        </div>
        <div>
          <AddSubscription
            adminId={branch?.[0]?.adminId}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-wrap gap-4 justify-evenly xl:justify-between mt-4">
          {[1, 2, 3].map((v, i) => {
            return (
              <div
                key={i}
                className="w-72 bg-white rounded-lg shadow pt-3 pb-1"
              >
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="animate-pulse w-12 h-12 rounded-full bg-skeletonLoaderColor  flex justify-center items-center mx-auto  mb-5">
                    <div className="h-2  bg-skeletonLoaderColor rounded "></div>
                  </div>
                  <div className=" mt-1 mb-1 tracking-wide w-3/5">
                    <div className=" flex flex-col justify-center items-center">
                      <div className="animate-pulse h-2 w-2/3 flex items-center justify-center font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                    </div>
                  </div>
                </div>

                <div className=" w-full ">
                  <div className=" flex flex-col justify-center items-center">
                    <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-wrap gap-4 justify-evenly  mt-4">
          <div className="w-72 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <FaUser size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Total Staffs
              </div>
            </div>
            <div className="font-bold text-lg w-full text-center">
              {staff.length}
            </div>
          </div>

          <div className="w-72 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <FaUser size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Status
              </div>
            </div>
            <div className="font-bold text-lg w-full text-center capitalize">
              {branch?.[0]?.status}
            </div>
            <div className="flex justify-center mt-3">
              <label className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={branch?.[0]?.status === 'active'}
                  onChange={handleStatusChange}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-1 peer-focus:ring-primaryColor dark:peer-focus:ring-primaryColor peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primaryColor"></div>
                {/* <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {branch?.[0]?.status === 'active' ? 'Active' : 'Inactive'}
                </span> */}
              </label>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="bg-[#fff] flex gap-5 p-5 mt-4 rounded-md shadow-lg ">
          <div className="flex flex-col justify-between gap-3 text-[#646671] text-lg w-1/3">
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
          </div>
          <div className="flex flex-col justify-between gap-3 text-[#646671] text-lg w-1/3">
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
          </div>
        </div>
      ) : (
        <div className="bg-white grid md:grid-cols-2 gap-4 p-4 mt-2 shadow-lg rounded-md">
          <div className="flex flex-col p-5 rounded-md border border-gray-400">
            <div className="text-lg font-semibold mb-2">Branch Details</div>
            <div className="flex">
              <div className="flex flex-col text-gray-600 w-1/3">
                <span>Name:</span>
                <span>Address:</span>
                <span>Phone:</span>
                <span>State:</span>
                <span>Email:</span>
                <span>City:</span>
                <span>Postal:</span>
              </div>
              <div className="flex flex-col justify-center">
                <span className="font-bold capitalize">{branch?.[0]?.name}</span>
                <span className="capitalize">{branch?.[0]?.address}</span>
                <span>{branch?.[0]?.primaryPhone}</span>
                <span className="capitalize">{branch?.[0]?.state}</span>
                <span>{branch?.[0]?.organizationEmail}</span>
                <span className="capitalize">{branch?.[0]?.city}</span>
                <span>{branch?.[0]?.postalCode}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col p-5 rounded-md border border-gray-400">
            <div className="text-lg font-semibold mb-2">Manager Details</div>
            <div className="flex">
              <div className="flex flex-col text-gray-600 w-1/3">
                <span>Name:</span>
                <span>Address:</span>
                <span>EmpId:</span>
                <span>Phone:</span>
                <span>Email:</span>
                <span>City:</span>
                <span>Gender:</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold capitalize">
                  {managers?.userId?.firstName}
                </span>
                <span>{managers?.address}</span>
                <span>{managers?.employeeId}</span>
                <span className="capitalize">{managers?.userId?.phone}</span>
                <span>{managers?.userId?.email}</span>
                <span className="capitalize">{managers?.city}</span>
                <span className="capitalize">{managers?.gender}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mx-auto mt-4">
        {isLoading ? (
          <React.Fragment>
            <div className="shadow rounded-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5].map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                <span className="text-xs xs:text-sm text-gray-900"></span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"></button>
                  <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"></button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="shadow rounded-lg overflow-x-auto">
            <div className="rounded-t-lg bg-white w-full flex p-4">
              <div className="font-bold text-2xl tracking-wide w-full">
                Staffs
              </div>
            </div>
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Emp Id
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Gender
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Phone No.
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {staff?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.employeeId}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.userId?.firstName + " " + v?.userId?.lastName}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.gender}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.userId?.phone}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing 1 to 4 of 50 Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default BranchDetails;
