import React from 'react';
import errImg from '../Assets/404.svg';
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    document.title = `${process.env.REACT_APP_NAME} | 404-Error`;
    let navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="flex justify-center">
                <img
                    className="h-[40%] w-[40%]"
                    src={errImg}
                    alt="page not found" />
            </div>

            <div className="btn-wrapper text-center my-8">
                <div className="text-xl text-[#2b2f32] font-bold">Opps..! Page not Found.</div>
            </div>

            <div className='w-full flex items-center justify-center mt-12'>
                <button
                    onClick={() => navigate("/superadmin/login")}
                    className='h-12 w-1/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]'>
                    Go to login
                </button>
            </div>
        </React.Fragment>
    )
}

export default PageNotFound
