import axios from "axios";

export const fetchAdmin = async () => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/admin/all-admin`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log("fetchAdmin Data Error:", error);
  }
};

export const fetchBranch = async () => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/organization/find-organization`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log("FetchBranch Error", error);
  }
};

export const fetchManager = async () => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/manager/find-manager`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log("Manager Error:", error);
  }
};

export const singleAdmin = async (id) => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/admin/${id}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const singleManager = async (id) => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/manager/get/${id}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchStaff = async () => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/staff/get-staff`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchIssue = async () => {
  const token = "Bearer " + localStorage.getItem("superAdminrefresh_token");

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/issue/find-issue`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
