import React from 'react'
import { fetchIssue } from '../../../FetchDataMethods/data';
import { useState } from 'react';
import { useEffect } from 'react';
import ResolveIssue from './ResolveIssue';

const IssuesTable = () => {
  const [issues, setIssues] = useState([]);
  const [openResolveIssue, setOpenResolveIssue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [id, setId] = useState("");

  useEffect(() => {
    fetchIssueData();
  }, [])

  const fetchIssueData = async () => {
    setIsLoading(true);
    const data = await fetchIssue();
    const sortedIssue = data?.sort((a, b) => a.createdAt.slice(0, 10) - b.createdAt.slice(0, 10))
    setIssues(sortedIssue);
    setIsLoading(false);
  };

  const handleOpenIssue = (id) => {
    setId(id);
    const initial = issues?.find((ele) => ele._id === id);
    setInitialValue(initial);
    setOpenResolveIssue(true);
  };

  return (
    <React.Fragment>

      {initialValue && <ResolveIssue
        openResolveIssue={openResolveIssue}
        setOpenResolveIssue={setOpenResolveIssue}
        issue={initialValue}
        fetchIssueData={  fetchIssueData}
      />}

      <div className="mx-auto mt-4">
        {isLoading ? (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900"></span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="animate-pulse h-2 w-1/2 flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"></button>
                <button className="animate-pulse h-2 w-1/2 flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"></button>
              </div>
            </div>
          </div>
        ) : issues?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Issues found
          </div>
        ) : (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Admin Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Branch Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Manager Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Cause
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Date
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {issues?.map((v, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => handleOpenIssue(v?._id)}
                      className="cursor-pointer transition ease-in-out delay-150 transform origin-top hover:-translate-y-1 duration-300 "
                    >
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap ">
                          {v?.admin?.firstName + " " + v?.admin?.lastName}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="cursor-pointer text-gray-900 whitespace-no-wrap ">
                          {v?.organization?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.manager?.firstName + " " + v?.manager?.lastName}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.subject?.length > 20 ? v?.subject.slice(0,20) + "...." : v?.subject}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.createdAt?.slice(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.isResolved ? "Resolved" : "Unresolved"}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default IssuesTable