import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditPackage from './PackageModal/EditPackage';
import { ImSearch } from 'react-icons/im';
import DeletePackage from './PackageModal/DeletePackage';
import Pagination from '../../../Screens/Common/Pagination';
import SearchBar from '../../../Screens/Common/SearchBar';
import Tostify from '../../../Screens/Common/Tostify';

const TableRow = ({ subscription, index, onEdit, onDelete }) => (
    <tr key={subscription._id}>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{index + 1}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.subscriptionName}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.duration}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">₹ {subscription?.price}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.createdAt?.slice(0, 10)}</td>
        <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm items-center flex">
            <EditPackage packageId={subscription._id} initialData={subscription} onEdit={onEdit} />
            <DeletePackage packageId={subscription._id} onDelete={onDelete} />
        </td>
    </tr>
);

const PackageList = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const totalPages = Math.ceil(filteredSubscriptions.length / itemsPerPage);

    const fetchSubscriptions = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/get-subscription`);
            setSubscriptions(response?.data);
            setFilteredSubscriptions(response?.data);
        } catch (error) {
            setError('Failed to fetch subscriptions');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        const results = subscriptions?.filter(subscription =>
            subscription?.subscriptionName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        setFilteredSubscriptions(results);
        setCurrentPage(1);
    }, [searchTerm, subscriptions]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEdit = () => {
        fetchSubscriptions();
    };

    const handleDelete = (deletedPackageId) => {
        setSubscriptions(prevSubscriptions =>
            prevSubscriptions.filter(subscription => subscription._id !== deletedPackageId)
        );
        setFilteredSubscriptions(prevFilteredSubscriptions =>
            prevFilteredSubscriptions.filter(subscription => subscription._id !== deletedPackageId)
        );
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentSubscriptions = filteredSubscriptions?.slice(indexOfFirstItem, indexOfLastItem);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">{error}</div>;
    }

    return (
        <>
            <Tostify />
            <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
            <div className="mx-auto mt-4 shadow rounded-lg overflow-x-auto">
                <table className="min-w-full leading-normal">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">#</th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Subscription Name</th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Duration</th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Price</th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Created At</th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentSubscriptions?.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                                    No subscriptions found
                                </td>
                            </tr>
                        ) : (
                            currentSubscriptions?.map((subscription, index) => (
                                <TableRow
                                    key={subscription._id}
                                    subscription={subscription}
                                    index={indexOfFirstItem + index}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            <Pagination
               currentPage={currentPage}
               totalPages={totalPages}
               handlePageChange={handlePageChange}
               indexOfFirstItem={indexOfFirstItem}
               itemsPerPage={itemsPerPage}
               totalItems={filteredSubscriptions.length}
            />
        </>
    );
};

export default PackageList;
