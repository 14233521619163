import React from 'react'

const Inventory = () => {
  return (
    <div>
      Inventory
    </div>
  )
}

export default Inventory
