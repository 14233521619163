import React, { useState } from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { FaBell } from 'react-icons/fa';
import AdminProfileDropDown from './AdminProfileDropDown';
import NotificationDropdown from './NotificationDropDown';
import { useEffect } from 'react';
import { fetchIssue } from '../../FetchDataMethods/data';

const Navbar = ({ toggleSidebar }) => {
  const [issues,setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date().toISOString().slice(0, 10);

  useEffect(()=>{
    fetchIssueData();
  },[])

  const fetchIssueData = async () => {
    setIsLoading(true);
    const data = await fetchIssue();
    const todaysIssue = data?.filter(item => item.createdAt.slice(0,10) === currentDate)
    setIssues(todaysIssue);
    setIsLoading(false);
};
  return (
    <div id="nav" className="w-full h-16 bg-white flex flex-row items-center text-gray-600 shadow z-50 sticky top-4">
      <div className="ml-5">
        <AiOutlineMenuUnfold
          className="transition ease-in-out hover:text-[#fe8740] hover:duration-300 mr-5"
          onClick={toggleSidebar}
          size={25} />
      </div>
      
      <div className="ml-auto flex flex-row items-center mr-5">
        
        <NotificationDropdown issues={issues}  />
        <AdminProfileDropDown />
      </div>
    </div>
  );
};

export default Navbar;
