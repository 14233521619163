import React from 'react'
import IssuesTable from '../../Components/SuperAdmin/Issues/IssuesTable'

const Issue = () => {
  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide w-full">
        Issues
      </div>
      <IssuesTable />
    </React.Fragment>
  )
}

export default Issue