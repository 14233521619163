import React from "react";
import { Navigate } from "react-router-dom";
import { decryptData } from "../../Screens/localStorageUtils";

const NonAuthLayout = (props) => {
  let userRole = decryptData()?.user?.role;
  if (localStorage.getItem("encryptedSuperAdminData")) {
    if (userRole === "superAdmin") {
      return <Navigate to={{ pathname: `/superadmin` }} />;
    }
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default NonAuthLayout;
