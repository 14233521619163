import React, { useEffect, useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';
import { BiSolidBellRing } from "react-icons/bi";

const NotificationDropdown = (props) => {
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const id = open ? 'simple-popup' : undefined;

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const handleClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    }

    const PopupBody = styled('div')(
        ({ theme }) => `
        width: max-content;
        padding: 12px 20px;
        margin: 8px;
        border-radius: 8px;
        background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        box-shadow: ${theme.palette.mode === 'dark'
                ? `0px 4px 8px rgb(0 0 0 / 0.7)`
                : `0px 4px 8px rgb(0 0 0 / 0.1)`
            };
        z-index: 1;
      `,
    );

    const [todayDetails, setTodayDetails] = useState(null);

    useEffect(() => {
        const getCurrentDateDetails = () => {
            const currentDate = new Date();

            const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
            const date = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            const time = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });

            setTodayDetails({
                dayOfWeek,
                date,
                time,
            });
        };

        getCurrentDateDetails();

    }, []);

    return (
        <React.Fragment>
            <div >
                <div className="relative flex items-center ">
                    <div
                        onClick={handleClick}
                        className=" transition ease-in-out hover:text-primaryColor hover:duration-300 mr-5 cursor-pointer">
                        <FaBell size={25} />
                    </div>

                    <div className="absolute top-0 right-5 bottom-4 bg-green-500 w-2.5 h-2.5 rounded-full"></div>
                </div>
                {/* <div
                    onClick={handleClick}
                    className="transition ease-in-out hover:text-primaryColor hover:duration-300 mr-5">
                    <FaBell size={25} />
                </div> */}

                <BasePopup
                    id={id}
                    open={open}
                    anchor={anchor}>
                    <PopupBody>
                        <div className='flex flex-col justify-center items-center'>
                            <div
                                onClick={handleClose}
                                className='w-full flex justify-end text-md font-semibold mb-[-16px] cursor-pointer'>
                                X
                            </div>

                            <div className="w-12 h-12 bg-lightBgColor rounded-full flex items-center justify-center shadow-md">
                                <span className='text-primaryColor'>
                                    <BiSolidBellRing size={28} />
                                </span>
                            </div>

                            <div className="text-center">
                                <h2 className="text-lg font-bold my-4 tracking-wide">Today's Issues</h2>
                                {todayDetails ? (
                                    <div className='text-gray-700 text-md tracking-wide font-semibold'>
                                        <div className="">
                                            {props?.issues?.length} issues raised today
                                        </div>

                                        <div className="my-2">
                                            {todayDetails.dayOfWeek}
                                        </div>

                                        <div className="">
                                            {todayDetails.date}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <div className="h-4 w-full rounded-md bg-skeletonLoaderColor"></div>

                                        <div className="h-4 my-2 w-full rounded-md bg-skeletonLoaderColor"></div>

                                        <div className="h-4 w-full rounded-md bg-skeletonLoaderColor"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </PopupBody>
                </BasePopup>
            </div>
        </React.Fragment >
    )
}

export default NotificationDropdown
