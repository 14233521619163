import axios from "axios";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from "@mui/material";
import { RiDeleteBin5Fill } from "react-icons/ri";

const DeletePackage = ({ packageId, onDelete }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleDeleteSubmit = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/subscription/delete-subscription/${packageId}`, {
                data: { deleteReason }
            });
            onDelete(packageId);
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete package:', error);
        }
    };

    return (
        <>
            <button
                onClick={handleOpenModal}

            >
                <RiDeleteBin5Fill className="text-red-500 hover:text-primaryHoverColor transition ease-in-out hover:duration-300 cursor-pointer"/>
            </button>

            <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>Delete Package</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the reason for deletion:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Delete Reason"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={deleteReason}
                        onChange={(e) => setDeleteReason(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeletePackage;
