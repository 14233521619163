import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import profile_pic from "../../Assets/user.png";
import { useEffect } from "react";
import { fetchBranch, singleAdmin } from "../../FetchDataMethods/data";
import AddBranch from "../../Components/SuperAdmin/Branch/BranchModal/AddBranch";
import EditAdmin from "../../Components/SuperAdmin/Admin/EditAdmin";
import Tostify from "../Common/Tostify";
import EditBranch from "../../Components/SuperAdmin/Branch/BranchModal/EditBranch";
import { MdModeEdit } from "react-icons/md";

const AdminDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [adminData, setAdminData] = useState({});
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditBranch, setOpenEditBranch] = useState(false);
  const [branchId, setBranchId] = useState('');

  const handleOpen = () => setOpenEditModal(true);
  const handleClose = () => setOpenEditModal(false);


  useEffect(() => {
    singleAdminData(id);
    fetchBranchData();
  }, []);

  const singleAdminData = async (id) => {
    setIsLoading(true);
    const data = await singleAdmin(id);
    setAdminData(data);
    setIsLoading(false);
  };
  const fetchBranchData = async () => {
    setIsLoading(true);
    const data = await fetchBranch();
    setBranches(data?.filter((item) => item?.adminId === id));
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Tostify />
      <div className="flex items-center my-3 justify-between">
        <div className="flex gap-2 items-center">
          <IoMdArrowRoundBack
            size={25}
            className="mt-3 cursor-pointer"
            onClick={() => navigate("/superadmin/admins")}
          />
          <div className="font-bold text-2xl mt-4 tracking-wide w-full ml-2">
            Admin
          </div>
        </div>
        <div className="flex gap-4">
          <button
            className="bg-primaryColor text-white px-4 py-2 rounded-md"
            onClick={handleOpen}
          >
            Edit Admin
          </button>
          <AddBranch
            fetchBranchData={fetchBranchData}
          />
        </div>
      </div>

      <EditAdmin
        open={openEditModal}
        handleClose={handleClose}
        adminId={id}
        singleAdminData={singleAdminData}
      />

      <EditBranch
        open={openEditBranch}
        onClose={() => setOpenEditBranch(false)}
        fetchBranchData={fetchBranchData}
        id={branchId}
      />

      {isLoading ? (
        // <div className="animate-pulse bg-skeletonLoaderColor rounded-md h-24 w-24 rounded-full mb-4" />
        <div className="flex flex-col md:flex-row bg-white rounded-md p-4 gap-4">
          <div className="flex flex-col justify-center items-center md:w-[45%] p-8 rounded-lg border border-gray-400">
            <div className="animate-pulse bg-skeletonLoaderColor h-24 w-24 rounded-full mb-4"></div>
            <div className="h-3 animate-pulse bg-skeletonLoaderColor rounded-md mb-2 w-1/2"></div>
            <div className="h-2 animate-pulse bg-skeletonLoaderColor rounded-md mb-2 w-1/5"></div>
          </div>

          <div className="flex flex-col justify-center md:w-[55%] p-4 rounded-lg border border-gray-400">
            <h2 className="h-3 animate-pulse bg-skeletonLoaderColor rounded-md w-1/5 mb-4">
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 text-gray-700">
              <div className="flex flex-col mb-2 lg:mb-0">
                <p className="mb-4 h-2 w-1/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
              </div>
              <div className="flex flex-col">
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
                <p className="mb-4 h-2 w-2/3 bg-skeletonLoaderColor rounded-md"></p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row bg-white rounded-md p-4 gap-4">
          <div className="flex flex-col justify-center items-center md:w-[45%] p-8 rounded-lg border border-gray-400">
            <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-400 mb-4">
              <img
                src={profile_pic}
                alt="Profile Picture"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-semibold">
                {adminData?.userId?.firstName +
                  " " +
                  adminData?.userId?.lastName}
              </h2>
              <p className="text-md text-gray-500">{adminData?.userId?.role}</p>
            </div>
          </div>

          <div className="flex flex-col justify-center md:w-[55%] p-4 rounded-lg border border-gray-400">
            <h2 className="text-xl font-semibold mb-2 text-center md:text-left">
              About
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-700">
              <div className="flex flex-col mb-2 lg:mb-0">
                <p className="mb-2">Gender: {adminData?.gender}</p>
                <p className="mb-2">Email: {adminData?.userId?.email}</p>
                <p>Phone: {adminData?.userId?.phone}</p>
              </div>
              <div className="flex flex-col">
                <p className="mb-2">Status: {adminData?.status}</p>
                <p>State: {adminData?.state}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-wrap justify-evenly gap-4 mt-4">
        {branches?.map((v, i) => {
          return (
            <div
              key={i}
              className="border w-80 h-56 border-gray-200 bg-[#fff] shadow-lg rounded-md flex flex-col justify-center p-3 gap-2 relative"
            >
              <div className="absolute top-2 right-4 cursor-pointer">
                <MdModeEdit
                  className="text-primaryColor text-lg"
                  onClick={() => {
                    setOpenEditBranch(true);
                    setBranchId(v?._id);
                  }
                  }
                />
              </div>
              <div className="w-full flex justify-center items-center ">
                <img src={Logo} alt="logo" className="w-28 h-28" />
              </div>

              <div
                onClick={() => navigate(`/superadmin/branch-details/${v?._id}`)}
                className="flex flex-col justify-center items-center cursor-pointer"
              >
                <div className="w-full flex items-center justify-center text-lg font-semibold text-primaryColor underline cursor-pointer capitalize">
                  {v?.name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default AdminDetails;
