import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { TbPackages } from "react-icons/tb";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const CreatePackage = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        subscriptionName: "",
        price: "",
        durationValue: "",
        durationUnit: "months",
    });
    const [errors, setErrors] = useState({});

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.subscriptionName) newErrors.subscriptionName = "Subscription name is required.";
        if (!formData.price || isNaN(formData.price) || formData.price <= 0) newErrors.price = "Valid price is required.";
        if (!formData.durationValue || isNaN(formData.durationValue) || formData.durationValue <= 0) newErrors.durationValue = "Valid duration is required.";
        return newErrors;
    };

    const handleAddService = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/create-subscription`, { ...formData, duration: formData?.durationValue + formData?.durationUnit }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("Subscription created successfully", response.data);
            tostifySuccess(response?.data?.message);
            handleClose();
            setFormData({
                subscriptionName: "",
                price: "",
                durationValue: "",
                durationUnit: "months",
            });
            setErrors({});
            setIsLoading(false);
        } catch (error) {
            console.error("Error creating subscription:", error);
            setErrors({ api: "Failed to create subscription. Please try again." });
            setIsLoading(false);
            tostifyErr(error?.response?.data?.message);
        }
    };

    return (
        <React.Fragment>
            <button onClick={handleOpen} className="btn-open-modal gap-2 h-10 w-28 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-lg shadow-lg shadow-[#ffdcc7]">
                Add
                <TbPackages size={24} />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[400px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={handleClose}
                                className="absolute top-6 right-5 text-3xl cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">
                                Add Subscription Package
                            </div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleAddService}>
                                <div className="relative w-full mb-3">
                                    <div className="text-[#132c4a] block text-sm mb-2">
                                        Name
                                        <span className="text-red-600 text-lg"> *</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="subscriptionName"
                                         autoComplete="off"
                                        value={formData.subscriptionName}
                                        onChange={handleChange}
                                        className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.subscriptionName ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`}
                                        placeholder="Subscription Name"
                                        required
                                    />
                                    {errors.subscriptionName && <div className="text-red-600 text-sm">{errors.subscriptionName}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <div className="text-[#132c4a] block text-sm mb-2">
                                        Price
                                        <span className="text-red-600 text-lg"> *</span>
                                    </div>
                                    <input
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                         autoComplete="off"
                                        onChange={handleChange}
                                        className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.price ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`}
                                        placeholder="Price"
                                        required
                                    />
                                    {errors.price && <div className="text-red-600 text-sm">{errors.price}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <div className="text-[#132c4a] block text-sm mb-2">
                                        Duration
                                        <span className="text-red-600 text-lg"> *</span>
                                    </div>
                                    <div className="flex gap-4">
                                        <input
                                            type="number"
                                            name="durationValue"
                                            min="1"
                                            value={formData.durationValue}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.durationValue ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`}
                                            placeholder="Duration"
                                            required
                                        />
                                        <select
                                            name="durationUnit"
                                            value={formData.durationUnit}
                                            onChange={handleChange}
                                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.durationUnit ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`}
                                        >
                                            <option value="weeks">Weeks</option>
                                            <option value="months">Months</option>
                                            <option value="years">Years</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        type="submit"
                                        className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                    >
                                        {isLoading ? <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                            : <span>Submit</span>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default CreatePackage;
