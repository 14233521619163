import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const AddSubscription = ({ adminId }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({

    });
    const [errors, setErrors] = useState({});
    const [packages, setPackages] = useState([]);
    const { id } = useParams();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFormData({
            subscriptionId: "",
        });
        setErrors({});
    };
    // console.log("adminId",adminId)

    const calculateExpirationDate = (subscriptionId) => {
        const selectedPackage = packages?.find(pkg => pkg._id === subscriptionId);
        if (!selectedPackage) return null;

        const [amount, unit] = selectedPackage.duration.match(/(\d+)(\w+)/).slice(1);
        const expirationDate = new Date();

        switch (unit) {
            case 'weeks':
                expirationDate.setDate(expirationDate.getDate() + parseInt(amount) * 7);
                break;
            case 'months':
                expirationDate.setMonth(expirationDate.getMonth() + parseInt(amount));
                break;
            case 'years':
                expirationDate.setFullYear(expirationDate.getFullYear() + parseInt(amount));
                break;
            default:
                return null;
        }

        return expirationDate.toISOString();
    };

    useEffect(() => {
        const fetchPackages = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/get-subscription`);
                setPackages(response.data);
            } catch (error) {
                console.error("Error fetching Packages:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPackages();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const validationErrors = {};
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        const expiredAt = calculateExpirationDate(formData?.subscriptionId);
        setIsLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/organization/assign-subscription`, {
                subscription: {
                    expiredAt: expiredAt,
                    adminId: adminId,
                    organizationId: id,
                    subscriptionId: formData?.subscriptionId
                }
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("superAdminrefresh_token")
                }
            });
            // fetchBranchData();
            handleClose();
        } catch (error) {
            console.error("Error creating Branch:", error);
            setErrors({ api: "Failed to create Branch. Please try again." });
        } finally {
            setIsLoading(false);
        }
    };

    const inputClassName = (fieldName) => `text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors[fieldName] ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`;

    return (
        <React.Fragment>
            <button onClick={handleOpen} className="btn-open-modal gap-2 h-10 w-28 flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-lg shadow-lg shadow-[#ffdcc7]">
                Add
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[80vh] min-w-[400px] sm:min-w-[450px] overflow-y-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span onClick={handleClose} className="absolute top-6 right-5 text-3xl cursor-pointer">x</span>
                            <div className="text-xl text-[#2b2f32] font-bold">Assign Subscription</div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleSubmit}>
                                <div className="relative w-full mb-3">
                                    <select
                                        name="subscriptionId"
                                        value={formData.subscriptionId}
                                        onChange={handleChange}
                                        className={inputClassName('subscriptionId')}
                                        required
                                    >
                                        <option value="">Choose Package</option>
                                        {packages?.map((pack) => (
                                            <option key={pack._id} value={pack._id}>
                                                {pack?.subscriptionName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.subscriptionId && <div className="text-red-600 text-sm">{errors.subscriptionId}</div>}
                                </div>

                                {errors.api && <div className="text-red-600 text-sm mb-3">{errors.api}</div>}

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        type="submit"
                                        className="h-12 w-4/5 flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <div className="w-6 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AddSubscription;