import React, { useState } from "react";
import { RiAdminFill } from "react-icons/ri";
import { FaUserGear } from "react-icons/fa6";
import { FaCodeBranch } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa6";

const HeaderCards = ({isLoading,managers,staff,admins,branches}) => {

  return (
    <div className="flex flex-wrap gap-4 justify-center mt-4">
      {isLoading ? (
        <div className="flex flex-wrap gap-4 justify-center ">
          {[1, 2, 3, 4, 5].map((v, i) => {
            return (
              <div
                className="w-44 bg-white rounded-lg shadow pt-3 pb-1"
                key={i}
              >
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="animate-pulse w-12 h-12 rounded-full bg-skeletonLoaderColor  flex justify-center items-center mx-auto  mb-5">
                    <div className="h-2  bg-skeletonLoaderColor rounded "></div>
                  </div>
                  <div className="font-semibold text-md text-[#7f8296] text-center mt-1 mb-1 tracking-wide w-3/5">
                    <div className=" flex flex-col justify-center items-center">
                      <div className="animate-pulse h-2 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                    </div>
                  </div>
                </div>

                <div className="font-bold text-lg w-full text-center">
                  <div className=" flex flex-col justify-center items-center">
                    <div className="animate-pulse h-3 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <React.Fragment>
          <div className="w-44 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <RiAdminFill size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Total Admins
              </div>
            </div>

            <div className="font-bold text-lg w-full text-center">{admins?.length}</div>
          </div>

          <div className="w-44 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <FaUserTie size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Total Managers
              </div>
            </div>

            <div className="font-bold text-lg w-full text-center">{managers?.length}</div>
          </div>

          <div className="w-44 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <FaCodeBranch size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Total Branch
              </div>
            </div>

            <div className="font-bold text-lg w-full text-center">{branches?.length}</div>
          </div>

          <div className="w-44 bg-white rounded-lg shadow pt-3 pb-1">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                <FaUserGear size={25} className="text-primaryColor" />
              </div>
              <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                Total Staffs
              </div>
            </div>

            <div className="font-bold text-lg w-full text-center">{staff?.length}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default HeaderCards;
