import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../Screens/SuperAdmin/Dashboard";
import Login from "../Screens/Auth/Login";
import SignUp from "../Screens/Auth/SignUp";
import Client from "../Screens/SuperAdmin/Client";
import Inventory from "../Screens/SuperAdmin/Inventory";
import Staff from "../Screens/SuperAdmin/Staff";
import Subscriptions from "../Screens/SuperAdmin/Subscriptions";
import Membership from "../Screens/SuperAdmin/Membership";
import Analytics from "../Screens/SuperAdmin/Analytics";
import Invoice from "../Screens/SuperAdmin/Invoice";
import Discounts from "../Screens/SuperAdmin/Discounts";
import PageNotFound from "../Screens/404";
import FirstRenderingPage from "../Screens/Common/FirstRenderingPage";
import Logout from "../Screens/Auth/Lougout";
import Profile from "../Screens/Common/Profile";
import Admin from "../Screens/SuperAdmin/Admin";
import Manager from "../Screens/SuperAdmin/Manager";
import Branch from "../Screens/SuperAdmin/Branch";
import AdminDetails from "../Screens/SuperAdmin/AdminDetails";
import ManagerDetails from "../Screens/SuperAdmin/ManagerDetails";
import BranchDetails from "../Screens/SuperAdmin/BranchDetails";
import ManagerBranchDetails from "../Screens/SuperAdmin/ManagerBranchDetails";
import Issue from "../Screens/SuperAdmin/Issue";
import Packages from "../Screens/SuperAdmin/Packages";

const userRoutes = [
  { path: "/superadmin/dashboard", component: <Dashboard /> },

  // this route should be at the end of all other routes
  {
    path: "/superadmin/",
    exact: true,
    component: <Navigate to="/superadmin/dashboard" />,
  },
  { path: "/superadmin/client", component: <Client /> },
  { path: "/superadmin/admins", component: <Admin /> },
  { path: "/superadmin/managers", component: <Manager /> },
  { path: "/superadmin/branches", component: <Branch /> },
  { path: "/superadmin/inventory", component: <Inventory /> },
  { path: "/superadmin/staff", component: <Staff /> },
  { path: "/superadmin/subscriptions", component: <Subscriptions /> },
  { path: "/superadmin/membership", component: <Membership /> },
  { path: "/superadmin/analytics", component: <Analytics /> },
  { path: "/superadmin/invoice", component: <Invoice /> },
  { path: "/superadmin/discounts", component: <Discounts /> },
  { path: "/superadmin/profile", component: <Profile /> },
  { path: "/superadmin/admin-details/:id", component: <AdminDetails /> },
  { path: "/superadmin/manager-details/:id", component: <ManagerDetails /> },
  { path: "/superadmin/branch-details/:id", component: <BranchDetails /> },
  { path: "/superadmin/manager-branch-details/:id", component: <ManagerBranchDetails /> },
  { path: "/superadmin/issue", component: <Issue /> },
  { path: "/superadmin/packages", component: <Packages /> },
  { path: "*", component: <PageNotFound /> },
];

const authRoutes = [
  { path: "/", component: <FirstRenderingPage /> },
  { path: "superadmin/login", component: <Login /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/logout", component: <Logout /> },
  { path: "*", component: <PageNotFound /> },
];

export { userRoutes, authRoutes };
