import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    outline: "none",
    borderRadius: "15px",
    p: 4,
};
const ResolveIssue = (props) => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateIssue = async () => {
        let data = JSON.stringify({
            "isResolved": Boolean(!(props?.issue?.isResolved))
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/issue/update/${props?.issue?._id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: data
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                tostifySuccess(response?.data?.message)
                setIsLoading(false);
                props?.fetchIssueData();
                props?.setOpenResolveIssue(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                tostifyErr(error?.response?.data?.message);
            });

    }

    return (
        <React.Fragment>
            <Modal
                open={props.openResolveIssue}
                onClose={() => {
                    props.setOpenResolveIssue(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    {props?.issue !== undefined ? (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-3 py-2 text-center">
                                            Sl.
                                        </th>
                                        <th scope="col" className="px-3 py-2 text-center">
                                            Cause
                                        </th>
                                        <th scope="col" className="px-3 py-2 text-center">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-2 text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="sm:border border-red-500 max-h-[300px] overflow-y-auto">
                                    <tr className="text-center">
                                        <td className="px-3 py-2">{1}</td>
                                        <td className="px-3 py-2">{props?.issue?.subject}</td>
                                        <td className="px-3 py-2">{props?.issue?.isResolved ? "Resolved" : "Unresolved"}</td>
                                        <td className="px-3 py-2 flex justify-center">
                                            <button
                                                disabled={props?.issue?.isResolved === true}
                                                onClick={() => handleUpdateIssue()}
                                                className={`h-10 p-2 flex content-center items-center justify-center text-lg font-semibold text-white ${props?.issue?.isResolved === true ? 'bg-gray-400 cursor-not-allowed' : 'bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300'
                                                    } rounded-xl shadow-lg shadow-[#ffdcc7]`}
                                            >
                                                {isLoading ? (
                                                    <div className="w-10 h-10 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                                ) : (
                                                    <span>Resolve</span>
                                                )}
                                            </button>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <h1 className="text-center">Issue  not found</h1>
                        </div>
                    )}
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ResolveIssue;
