import React from "react";
import logoutbg from "../../Assets/Logout/logout.png";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  document.title = `${process.env.REACT_APP_NAME} | Logout`;
  let navigate = useNavigate();

  const goToLogIn = () => {
    navigate("/superadmin/login");
  };
  return (
    <React.Fragment>
      <div
        style={{ backgroundImage: `url(${logoutbg})` }}
        className="bg-cover bg-center h-screen">
        <div className="flex justify-center items-center h-screen">

          <div className="flex flex-col shadow-lg rounded-3xl bg-white px-10 py-8">
            <div className="text-center font-bold text-2xl">
              <span className="text-primaryColor">
                All done !
              </span>
              &nbsp;
              <span className="text-[#2b2f32]">
                Have a nice day.
              </span>
            </div>

            <div className="text-md text-center">
              <div className="text-[#718092] font-semibold text-2xl mt-5 mb-8">
                A bit more to do?
              </div>

              <div className="w-full flex items-center justify-center">
                <button
                  onClick={goToLogIn}
                  className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                >
                  Back To Log In
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Logout;
