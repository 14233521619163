import React, { useState } from "react";
import { ImSearch } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { fetchManager } from "../../../FetchDataMethods/data";
import { useEffect } from "react";

const ManagerTable = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    fetchManagerData();
  }, []);

  const fetchManagerData = async () => {
    setIsLoading(true);
    const data = await fetchManager();
    setManagers(data);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setClicked(false);
    setResults([]);
    setSearchData(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const result = managers?.filter((item) =>
      item?.userId?.firstName?.toLowerCase().includes(searchData.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };

  return (
    <React.Fragment>
      <div className="mt-4 flex justify-between items-center flex-wrap gap-5">
        <form onSubmit={(e) => handleSearch(e)}>
          <div className="bg-white rounded shadow w-52 flex p-1 relative ">
            <input
              className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
              placeholder="Search..."
              type="text"
              value={searchData}
              onChange={handleChange}
            />
            {searchData && (
              <span
                className="absolute right-14 top-3 cursor-pointer"
                onClick={handleClear}
              >
                X
              </span>
            )}
            <button
              onClick={handleSearch}
              className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
            >
              <ImSearch color="white" />
            </button>
          </div>
        </form>
      </div>

      <div className="mx-auto mt-4">
        {isLoading ? (
          <React.Fragment>
            <div className="shadow rounded-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-200 text-gray-600 tracking-wide">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5].map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className=" flex flex-row  items-center">
                            <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                <span className="text-xs xs:text-sm text-gray-900"></span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button className="animate-pulse h-2 w-1/2 flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"></button>
                  <button className="animate-pulse h-2 w-1/2 flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"></button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : managers?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Managers found
          </div>
        ) : searchData && results.length > 0 ? (
          <div className="shadow rounded-lg overflow-x-auto flex flex-col">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Email
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Gender
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Phone No.
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((v, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() =>
                        navigate(`/superadmin/admin-details/${v?._id}`)
                      }
                      className="cursor-pointer transition ease-in-out delay-150 transform origin-top hover:-translate-y-1 duration-300 "
                    >
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap ">
                          {v?.userId?.email}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="cursor-pointer text-gray-900 whitespace-no-wrap ">
                          {v?.userId?.firstName + " " + v?.userId?.lastName}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.gender}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.userId?.phone}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : searchData && results.length === 0 && clicked ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Managers found
          </div>
        ) : (
          <div className="shadow rounded-lg overflow-x-auto flex flex-col">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Email
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Gender
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Phone No.
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {managers?.map((v, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() =>
                        navigate(`/superadmin/manager-details/${v?._id}`)
                      }
                      className="cursor-pointer transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1  duration-300 "
                    >
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                        <div className="text-gray-900 whitespace-no-wrap ">
                          {v?.userId?.email}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="cursor-pointer text-gray-900 whitespace-no-wrap ">
                          {v?.userId?.firstName + " " + v?.userId?.lastName}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.gender}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.userId?.phone}
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap capitalize">
                          {v?.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between w-full">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing 1 to 4 of 50 Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ManagerTable;
