import React from 'react'

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <footer className="footer bg-white shadow text-[#7f8296] px-5 py-4 mt-4">
                <div className="">
                    <div className="sm:flex sm:justify-between">
                        <div className=" mb-4 md:mb-0">
                            © Copyright {currentYear} <span className='text-primaryColor font-semibold'>
                                All rights reserved.
                            </span>
                        </div>

                        <div className="">
                            <div className="text-sm d-none d-sm-block">
                                Powered by <span className='text-primaryColor font-semibold'>
                                i-Bar.
                                </span>
                            </div>
                        </div>

                        {/* <div className="">
                            <div className="text-sm d-none d-sm-block">
                                Design & Develop by
                                <a href="https://webbocket.com/" target="_blank" rel="noopener noreferrer" className="ms-1 underline text-primaryColor hover:text-primaryHoverColor font-bold">
                                    Web_Bocket Pvt. Ltd.
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer
