import React from 'react'

const Staff = () => {
  return (
    <div>
      Staff
    </div>
  )
}

export default Staff
