import React from 'react';
import './Sidebar.css';
import SidebarsContent from './SidebarsContent';
import { ImScissors } from 'react-icons/im';

const Sidebar = ({ isOpen, toggleSidebar }) => {

  return (
    <React.Fragment>
      {
        isOpen &&
        <div className="scroll-container overflow-y-auto left-0 w-64 bg-white shadow fixed inset-y-0 transform transition-transform translate-x-0 py-8 px-6">
          <div className='flex justify-start px-4 mb-5 items-center'>
            <ImScissors size={30} color='#fe8740'/>
            <div className='text-2xl font-bold ml-2'>
              I-Bar
            </div>
          </div>
          <SidebarsContent />
        </div>
      }
    </React.Fragment>
  );
};

export default Sidebar;