import React from "react";
import BranchesList from "../../Components/SuperAdmin/Branch/BranchList";

const Branch = () => {
  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide w-full">
        Branches
      </div>

      <BranchesList />
    </React.Fragment>
  );
};

export default Branch;
