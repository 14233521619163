import React, { useEffect } from "react";
import { decryptData } from "../localStorageUtils";
import { useNavigate } from "react-router-dom";

const FirstRenderingPage = () => {
  let navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("encryptedSuperAdminData")) {
      navigate("superAdmin/login");
    } else {
      let userRole = decryptData()?.user?.role;
      if (userRole === "superAdmin") {
        navigate(`/${userRole}`);
      }
    }
  }, []);

  return <React.Fragment></React.Fragment>;
};

export default FirstRenderingPage;
