import React, { useState } from "react";
import { ImSearch } from "react-icons/im";
import Logo from "../../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchBranch } from "../../../FetchDataMethods/data";

const BranchesList = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    fetchBranchData();
  }, []);

  const fetchBranchData = async () => {
    setIsLoading(true);
    const data = await fetchBranch();
    setBranches(data)
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="mt-4 flex justify-between items-center flex-wrap gap-5">
        <form>
          <div className="bg-white rounded shadow w-52 flex p-1 relative ">
            <input
              className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
              placeholder="Search..."
              type="text"
            />
            <button className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2">
              <ImSearch color="white" />
            </button>
          </div>
        </form>
      </div>

      <div className="flex flex-wrap justify-evenly gap-4 mt-4">
        {isLoading ? (
          <React.Fragment>
            {[1, 2, 3].map((v, i) => {
              return (
                <div
                  key={i}
                  className=" w-64 bg-white rounded-lg shadow p-8 relative"
                >
                  <div className="animate-pulse w-24 h-24 rounded-full bg-skeletonLoaderColor  flex justify-center items-center mx-auto  mb-5">
                    <div className="h-2  bg-skeletonLoaderColor rounded "></div>
                  </div>

                  <div className=" flex flex-col justify-center items-center">
                    <div className="animate-pulse h-3 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                  </div>
                  <div className=" flex flex-col justify-center items-center">
                    <div className="animate-pulse h-6 w-4/5 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          branches?.map((crds, i) => {
            return (
              <div className=" w-64 bg-white rounded-lg shadow p-8" key={i}>
                <div className="w-full flex justify-center items-center ">
                  <img src={Logo} alt="logo" className="w-28 h-24" />
                </div>

                <div className="flex flex-col justify-center items-center">
                  <div className="font-bold text-xl text-center tracking-wide my-2 capitalize">
                    {crds?.name}
                  </div>

                  <div
                    onClick={() => navigate(`/superadmin/branch-details/${crds?._id}`)}
                    className="w-full flex items-center justify-center mt-5"
                  >
                    <button className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </React.Fragment>
  );
};

export default BranchesList;
