/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { MdOutlineEdit } from "react-icons/md";
import profile_pic from "../../Assets/user.png";
import { decryptData } from "../localStorageUtils";

const Profile = () => {
  document.title = `${process.env.REACT_APP_NAME} | User Profile`;
  const decryptedData = decryptData();
  console.log("decryptedData", decryptedData);

  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide">Profile</div>

      <div className="w-full bg-white flex flex-wrap rounded-lg p-4 mt-4">
        <div className="py-3 md:flex items-center w-full border border-[#979797] rounded-lg my-auto">
          <div className="flex justify-center items-center mx-8">
            <div
              className={`w-16 h-16 rounded-full overflow-hidden border-2 border-gray-400`}
            >
              <img
                src={profile_pic}
                alt="Profile Picture"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="md:py-4 text-center md:text-start">
            <div className="flex items-center md:justify-start justify-center">
              <span className="font-extrabold text-2xl text-[#2B2F32] uppercase">
                {decryptedData?.user?.firstName} &nbsp;{decryptedData?.user?.lastName}
              </span>

              <span className="font-semibold text-xl text-[#979797] capitalize">
                SuperAdmin
              </span>
            </div>

            <div className="font-semibold text-xl text-[#979797] capitalize">
              BBSR
            </div>
          </div>
        </div>

        <div className="flex w-full p-8 flex-wrap shadow rounded-lg mt-4 border border-[#979797] ">
          <div className="flex justify-between w-full">
            <div className="font-bold text-2xl px-5">Profile Details</div>
            <div className="flex bg-gray-200 hover:bg-gray-300 p-1 pr-2 rounded-md cursor-pointer transition ease-in-out h-max hover:duration-300">
              <MdOutlineEdit size={20} className="mt-1" />
              &nbsp; <span className="text-lg">Edit</span>
            </div>
          </div>

          <div className="text-justify md:flex gap-20 w-full md:justify-start items-center max-w-screen">
            <div className="px-5">
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Name
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  {decryptedData?.user?.firstName} &nbsp; {decryptedData?.user?.lastName}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Gender
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  Male
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Employment Time
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  Full Time
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Date of join
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {(decryptedData?.user?.createdAt)?.slice(0, 10)}
                </span>
              </div>
            </div>

            <div className="px-5">
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Email
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {decryptedData?.user?.email}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Phone Number
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {decryptedData?.user?.phone}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Status
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  Active
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Role
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  Admin
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
