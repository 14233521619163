import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const AddBranch = ({ fetchBranchData }) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        postalCode: "",
        city: "",
        state: "",
        primaryPhone: "",
        secondaryPhone: "",
        organizationEmail: "",
        subscriptionId: "",
        url: "",
    });
    const [branchImage, setBranchImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const { id } = useParams();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFormData({
            name: "",
            address: "",
            postalCode: "",
            city: "",
            state: "",
            primaryPhone: "",
            secondaryPhone: "",
            organizationEmail: "",
            subscriptionId: "",
            url: "",
        });
        setBranchImage(null);
        setErrors({});
    };

    useEffect(() => {
        const fetchSubscriptions = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/get-subscription`);
                setSubscriptions(response.data);
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchSubscriptions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const calculateExpirationDate = (subscriptionId) => {
        const selectedPackage = subscriptions?.find(pkg => pkg._id === subscriptionId);
        if (!selectedPackage) return null;

        const [amount, unit] = selectedPackage?.duration?.match(/(\d+)(\w+)/)?.slice(1);
        const expirationDate = new Date();

        switch (unit) {
            case 'weeks':
                expirationDate.setDate(expirationDate.getDate() + parseInt(amount) * 7);
                break;
            case 'months':
                expirationDate.setMonth(expirationDate.getMonth() + parseInt(amount));
                break;
            case 'years':
                expirationDate.setFullYear(expirationDate.getFullYear() + parseInt(amount));
                break;
            default:
                return null;
        }

        return expirationDate?.toISOString();
    };

    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "i_bar_profile");

        try {
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
                formData
            );
            return response.data.secure_url;
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    const onBranchDrop = async (acceptedFiles) => {
        const url = await uploadToCloudinary(acceptedFiles[0]);
        setBranchImage(url);
    };

    const { getRootProps: getBranchRootProps, getInputProps: getBranchInputProps } = useDropzone({ onDrop: onBranchDrop });

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Name is required.";
        if (!formData.address) validationErrors.address = "Address is required.";
        if (!formData.primaryPhone) validationErrors.primaryPhone = "Primary phone is required.";
        if (!formData.subscriptionId) validationErrors.subscriptionId = "Subscription ID is required.";
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        const expiredAt = calculateExpirationDate(formData?.subscriptionId);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/organization/add`, {
                ...formData,
                adminId: id,
                url: branchImage,
                expiredAt: expiredAt,
                primaryPhone: "+91" + formData?.primaryPhone
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("superAdminrefresh_token")
                }
            });

            fetchBranchData();
            handleClose();
        } catch (error) {
            console.error("Error creating Branch:", error);
            setErrors({ api: "Failed to create Branch. Please try again." });
        } finally {
            setIsLoading(false);
        }
    };

    const inputClassName = (fieldName) => `text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors[fieldName] ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`;

    return (
        <React.Fragment>
            <button onClick={handleOpen} className="btn-open-modal gap-2 h-10 w-28 flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-lg shadow-lg shadow-[#ffdcc7]">
                Add
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[80vh] min-w-[400px] sm:min-w-[450px] overflow-y-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span onClick={handleClose} className="absolute top-6 right-5 text-3xl cursor-pointer">x</span>
                            <div className="text-xl text-[#2b2f32] font-bold">Add Branch</div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleSubmit}>
                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        autoComplete="off"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={inputClassName('name')}
                                        placeholder="Branch Name"
                                        required
                                    />
                                    {errors.name && <div className="text-red-600 text-sm">{errors.name}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="address"
                                        autoComplete="off"
                                        value={formData.address}
                                        onChange={handleChange}
                                        className={inputClassName('address')}
                                        placeholder="Branch Address"
                                        required
                                    />
                                    {errors.address && <div className="text-red-600 text-sm">{errors.address}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="postalCode"
                                        autoComplete="off"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        className={inputClassName('postalCode')}
                                        placeholder="Postal Code"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="city"
                                        autoComplete="off"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className={inputClassName('city')}
                                        placeholder="City"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="state"
                                        autoComplete="off"
                                        value={formData.state}
                                        onChange={handleChange}
                                        className={inputClassName('state')}
                                        placeholder="State"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="tel"
                                        name="primaryPhone"
                                        autoComplete="off"
                                        value={formData.primaryPhone}
                                        onChange={handleChange}
                                        className={inputClassName('primaryPhone')}
                                        placeholder="Primary Phone"
                                        required
                                    />
                                    {errors.primaryPhone && <div className="text-red-600 text-sm">{errors.primaryPhone}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="tel"
                                        name="secondaryPhone"
                                        autoComplete="off"
                                        value={formData.secondaryPhone}
                                        onChange={handleChange}
                                        className={inputClassName('secondaryPhone')}
                                        placeholder="Secondary Phone"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="email"
                                        name="organizationEmail"
                                        autoComplete="off"
                                        value={formData.organizationEmail}
                                        onChange={handleChange}
                                        className={inputClassName('organizationEmail')}
                                        placeholder="Organization Email"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <select
                                        name="subscriptionId"
                                        value={formData.subscriptionId}
                                        onChange={handleChange}
                                        className={inputClassName('subscriptionId')}
                                        required
                                    >
                                        <option value="">Select Subscription</option>
                                        {subscriptions?.map((subscription) => (
                                            <option key={subscription._id} value={subscription._id}>
                                                {subscription?.subscriptionName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.subscriptionId && <div className="text-red-600 text-sm">{errors.subscriptionId}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <label className="text-[#132c4a] block text-sm mb-2">
                                        Branch Image <span className="text-red-600 text-lg">*</span>
                                    </label>
                                    <div {...getBranchRootProps()} className="dropzone border-2 border-dashed border-gray-300 p-4 text-center cursor-pointer">
                                        <input {...getBranchInputProps()} />
                                        <p>Drag 'n' drop branch image here, or click to select</p>
                                    </div>
                                    {branchImage && (
                                        <div className="mt-2">
                                            <img src={branchImage} alt="Branch" className="w-32 h-32 object-cover" />
                                        </div>
                                    )}
                                    {errors.branchImage && <div className="text-red-600 text-sm">{errors.branchImage}</div>}
                                </div>

                                {errors.api && <div className="text-red-600 text-sm mb-3">{errors.api}</div>}

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        type="submit"
                                        className="h-12 w-4/5 flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AddBranch;