import React from "react";
import AdminTable from "../../Components/SuperAdmin/Admin/AdminTable";

const Admin = () => {
  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide w-full">Admins</div>

      <AdminTable />
    </React.Fragment>
  );
};

export default Admin;
