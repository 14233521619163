import React from 'react';
import SubscriptionTableRow from './SubscriptionTableRow';

const SubscriptionTable = ({ subscriptions, currentPage, itemsPerPage, fetchSubscriptions }) => (
    <div className="mx-auto mt-4 shadow rounded-lg overflow-x-auto">
        <table className="min-w-full leading-normal">
            <thead>
                <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">#</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Subscription Name</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Organization</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Admin</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Expiry Date</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Created At</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Action</th>
                </tr>
            </thead>
            <tbody>
                {subscriptions?.map((subscription, index) => (
                    <SubscriptionTableRow
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        key={subscription?._id}
                        subscription={subscription}
                        index={index}
                        fetchSubscriptions={fetchSubscriptions}
                    />
                ))}
            </tbody>
        </table>
    </div>
);

export default SubscriptionTable;
