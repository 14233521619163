import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";

const EditAdmin = ({ open, handleClose, singleAdminData, adminId }) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        gstNumber: "",
        city: "",
        state: "",
        gender: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [adminImage, setAdminImage] = useState(null);
    const token = localStorage.getItem("superAdminrefresh_token");

    console.log("adminData", formData);

    useEffect(() => {
        if (!open) return;

        const fetchAdmin = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/admin/${adminId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }
                );

                const admin = response?.data?.data;

                if (admin) {
                    setFormData({
                        firstName: admin?.userId?.firstName || "",
                        lastName: admin?.userId?.lastName || "",
                        email: admin?.userId?.email || "",
                        phone: admin?.userId?.phone.slice(3),
                        password: "", 
                        gstNumber: admin.gstNumber || "", 
                        city: admin.city || "",
                        state: admin.state || "",
                        gender: admin.gender || "",
                    });
                    setAdminImage(admin.adminImage);
                }

            } catch (error) {
                console.error("Error fetching admin:", error);
            }
        };

        fetchAdmin();

    }, [open, adminId, token]);

    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "i_bar_profile");

        try {
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
                formData
            );
            return response.data.secure_url;
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    const onAdminDrop = async (acceptedFiles) => {
        const url = await uploadToCloudinary(acceptedFiles[0]);
        setAdminImage(url);
    };

    const { getRootProps: getAdminRootProps, getInputProps: getAdminInputProps } = useDropzone({ onDrop: onAdminDrop });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.firstName) newErrors.firstName = "First Name is required";
        if (!formData.lastName) newErrors.lastName = "Last Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.phone) newErrors.phone = "Phone is required";
        if (!formData.city) newErrors.city = "City is required";
        if (!formData.state) newErrors.state = "State is required";
        if (!formData.gender) newErrors.gender = "Gender is required";

        if (formData.password) {
            const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,30}$/;
            if (!passwordRegEx.test(formData.password)) {
                newErrors.password = "Password must contain 8-30 characters, including uppercase, lowercase, numbers and special characters";
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setIsLoading(true);
        try {
            const payload = {
                ...formData,
                phone: "+91" + formData?.phone,
                url: adminImage,
            };

            if (!formData.password) {
                delete payload.password;
            }

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/admin/update/${adminId}`,
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("superAdminrefresh_token")
                    }
                }
            );

            handleClose();
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                gstNumber: "",
                city: "",
                state: "",
                gender: "",
            });
            singleAdminData(adminId);
            setAdminImage(null);
            tostifySuccess("Admin updated Successfully");
        } catch (error) {
            console.error("Error updating admin:", error);
            tostifyErr(error?.response?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" sx={{ mb: 2, color: "#132c4a" }}>
                    Create Admin with Branch
                </Typography>
                <form onSubmit={handleSubmit} className="max-h-[450px] overflow-y-auto no-scrollbar px-2">
                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            First Name <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            autoComplete="off"
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.firstName ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="First Name"
                        />
                        {errors.firstName && <div className="text-red-600 text-sm">{errors.firstName}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Last Name <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            autoComplete="off"
                            value={formData.lastName}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.lastName ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="Last Name"
                        />
                        {errors.lastName && <div className="text-red-600 text-sm">{errors.lastName}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Email <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            autoComplete="off"
                            value={formData.email}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.email ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="Email"
                        />
                        {errors.email && <div className="text-red-600 text-sm">{errors.email}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Phone <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.phone ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="Phone"
                        />
                        {errors.phone && <div className="text-red-600 text-sm">{errors.phone}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Password {!formData.password && <span className="text-gray-500 text-xs">(Leave empty to keep current password)</span>}
                        </label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.password ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="Enter new password"
                        />
                        {errors.password && <div className="text-red-600 text-sm">{errors.password}</div>}
                        <div className="text-gray-500 text-xs mt-1">
                            Password must contain 8-30 characters, including uppercase, lowercase, numbers and special characters
                        </div>
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            GST Number
                        </label>
                        <input
                            type="text"
                            name="gstNumber"
                            value={formData.gstNumber}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.gstNumber ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="GST Number"
                        />
                        {errors.gstNumber && <div className="text-red-600 text-sm">{errors.gstNumber}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            City <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.city ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="City"
                        />
                        {errors.city && <div className="text-red-600 text-sm">{errors.city}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            State <span className="text-red-600 text-lg">*</span>
                        </label>
                        <input
                            type="text"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.state ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                            placeholder="State"
                        />
                        {errors.state && <div className="text-red-600 text-sm">{errors.state}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Gender <span className="text-red-600 text-lg">*</span>
                        </label>
                        <select
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className={`text-[#132c4a] border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.gender ? "ring-red-600" : "ring-[#845ff6]"
                                } w-full ease-linear transition-all duration-150`}
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        {errors.gender && <div className="text-red-600 text-sm">{errors.gender}</div>}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="text-[#132c4a] block text-sm mb-2">
                            Admin Profile Image
                        </label>
                        <div {...getAdminRootProps()} className="dropzone border-2 border-dashed border-gray-300 p-4 text-center cursor-pointer">
                            <input {...getAdminInputProps()} />
                            <p>Drag 'n' drop admin image here, or click to select</p>
                        </div>
                        {adminImage && (
                            <div className="mt-2">
                                <img src={adminImage} alt="Admin Profile" className="w-32 h-32 object-cover" />
                            </div>
                        )}
                        {errors.adminImage && <div className="text-red-600 text-sm">{errors.adminImage}</div>}
                    </div>


                    <div className="w-full flex items-center justify-center mt-5">
                        <button
                            type="submit"
                            className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                            ) : (
                                <span>Submit</span>
                            )}
                        </button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default EditAdmin;