import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const EditBranch = ({ fetchBranchData, open, onClose, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        postalCode: "",
        city: "",
        state: "",
        primaryPhone: "",
        secondaryPhone: "",
        organizationEmail: "",
        url: "",
    });
    const [branchImage, setBranchImage] = useState(null);
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setFormData({
            name: "",
            address: "",
            postalCode: "",
            city: "",
            state: "",
            primaryPhone: "",
            secondaryPhone: "",
            organizationEmail: "",
            url: "",
        });
        setBranchImage(null);
        setErrors({});
    };

    useEffect(() => {
        if (!open) return;
        const fetchBranch = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/organization/get-branch-by-id/${id}`);
                const branchData = response?.data?.data; 

                setFormData({
                    name: branchData?.name || "",
                    address: branchData?.address || "",
                    postalCode: branchData?.postalCode || "",
                    city: branchData?.city || "",
                    state: branchData?.state || "",
                    primaryPhone: branchData?.primaryPhone?.slice(3) || "",
                    secondaryPhone: branchData?.secondaryPhone || "",
                    organizationEmail: branchData?.organizationEmail || "",
                    url: branchData?.url || "",
                });

                // Set branch image if it exists
                if (branchData?.url) {
                    setBranchImage(branchData.url);
                }

            } catch (error) {
                console.error("Error fetching branch:", error);
                tostifyErr("Failed to fetch branch details");
            } finally {
                setIsLoading(false);
            }
        };
        fetchBranch();
    }, [id, open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const uploadToCloudinary = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "i_bar_profile");

        try {
            const response = await axios.post(
                `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
                formData
            );
            return response.data.secure_url;
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    const onBranchDrop = async (acceptedFiles) => {
        try {
            const url = await uploadToCloudinary(acceptedFiles[0]);
            if (url) {
                setBranchImage(url);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            setErrors(prev => ({ ...prev, branchImage: "Failed to upload image" }));
        }
    };

    const { getRootProps: getBranchRootProps, getInputProps: getBranchInputProps } = useDropzone({ onDrop: onBranchDrop });

    const validateForm = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = "Name is required.";
        if (!formData.address) validationErrors.address = "Address is required.";
        if (!formData.primaryPhone) validationErrors.primaryPhone = "Primary phone is required.";
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        try {
            const payload = {
                ...formData,
                primaryPhone: "+91" + formData?.primaryPhone
            };

            if (branchImage !== formData.url) {
                payload.url = branchImage;
            }

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/organization/update/${id}`,
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("superAdminrefresh_token")
                    }
                }
            );

            fetchBranchData();
            handleClose();
            onClose()
            tostifySuccess("Branch updated successfully");
        } catch (error) {
            console.error("Error updating Branch:", error);
            setErrors({ api: "Failed to update Branch. Please try again." });
            tostifyErr("Failed to update Branch. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const inputClassName = (fieldName) => `text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors[fieldName] ? 'ring-red-600' : 'ring-[#845ff6]'} w-full ease-linear transition-all duration-150 pr-10`;

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[80vh] min-w-[400px] sm:min-w-[450px] overflow-y-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span onClick={onClose} className="absolute top-6 right-5 text-3xl cursor-pointer">x</span>
                            <div className="text-xl text-[#2b2f32] font-bold">Edit Branch</div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleSubmit}>
                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        autoComplete="off"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={inputClassName('name')}
                                        placeholder="Branch Name"
                                        required
                                    />
                                    {errors.name && <div className="text-red-600 text-sm">{errors.name}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="address"
                                        autoComplete="off"
                                        value={formData.address}
                                        onChange={handleChange}
                                        className={inputClassName('address')}
                                        placeholder="Branch Address"
                                        required
                                    />
                                    {errors.address && <div className="text-red-600 text-sm">{errors.address}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="postalCode"
                                        autoComplete="off"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        className={inputClassName('postalCode')}
                                        placeholder="Postal Code"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="city"
                                        autoComplete="off"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className={inputClassName('city')}
                                        placeholder="City"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="text"
                                        name="state"
                                        autoComplete="off"
                                        value={formData.state}
                                        onChange={handleChange}
                                        className={inputClassName('state')}
                                        placeholder="State"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="tel"
                                        name="primaryPhone"
                                        autoComplete="off"
                                        value={formData.primaryPhone}
                                        onChange={handleChange}
                                        className={inputClassName('primaryPhone')}
                                        placeholder="Primary Phone"
                                        required
                                    />
                                    {errors.primaryPhone && <div className="text-red-600 text-sm">{errors.primaryPhone}</div>}
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="tel"
                                        name="secondaryPhone"
                                        autoComplete="off"
                                        value={formData.secondaryPhone}
                                        onChange={handleChange}
                                        className={inputClassName('secondaryPhone')}
                                        placeholder="Secondary Phone"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <input
                                        type="email"
                                        name="organizationEmail"
                                        autoComplete="off"
                                        value={formData.organizationEmail}
                                        onChange={handleChange}
                                        className={inputClassName('organizationEmail')}
                                        placeholder="Organization Email"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label className="text-[#132c4a] block text-sm mb-2">
                                        Branch Image
                                        {!branchImage && <span className="text-gray-500 text-xs ml-2">(Leave empty to keep current image)</span>}
                                    </label>
                                    <div {...getBranchRootProps()} className="dropzone border-2 border-dashed border-gray-300 p-4 text-center cursor-pointer">
                                        <input {...getBranchInputProps()} />
                                        <p>{branchImage ? 'Click or drag to change image' : 'Drag & drop branch image here, or click to select'}</p>
                                    </div>
                                    {branchImage && (
                                        <div className="mt-2 relative">
                                            <img src={branchImage} alt="Branch" className="w-32 h-32 object-cover rounded" />
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setBranchImage(null);
                                                }}
                                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                                            >
                                                <span className="text-sm">×</span>
                                            </button>
                                        </div>
                                    )}
                                    {errors.branchImage && <div className="text-red-600 text-sm">{errors.branchImage}</div>}
                                </div>

                                {errors.api && <div className="text-red-600 text-sm mb-3">{errors.api}</div>}

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        type="submit"
                                        className="h-12 w-4/5 flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <span>Submit</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default EditBranch;