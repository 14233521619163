import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import lgnBackPic from "../../Assets/Login/login-backgraound.webp";
import axios from "axios";
import { encryptData } from "../localStorageUtils";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Tostify from "../Common/Tostify";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [country, setCountry] = useState("+91");
  const [contryErr, setCountryErr] = useState("");
  const updatedPhone = country + phone;
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // On component mount, check if there are stored credentials and update the state
    const storedEmail = localStorage.getItem("rememberedemail");
    const storedPhone = localStorage.getItem("rememberedphone");
    const storedPassword = localStorage.getItem("rememberedpassword");

    if (storedEmail) setEmail(storedEmail);
    if (storedPhone) setPhone(storedPhone?.slice(3, 13));
    if (storedPassword) setPassword(storedPassword);
  }, []);

  const rememberMeBtn = () => {
    if (rememberMe) {
      localStorage.setItem("rememberedemail", email);
      localStorage.setItem("rememberedphone", updatedPhone);
      localStorage.setItem("rememberedpassword", password);
    }
  };

  const handleClick = () => {
    setVisible((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // if (!email) {
    //   setEmailErr(true);
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //   setEmailErr(true);
    // }
    if (!phone) {
      setPhoneErr(true);
    } else if (!password) {
      setPasswordErr(true);
    } else {
      let data = JSON.stringify({
        // email: email,
        phone: updatedPhone,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      if (rememberMe) {
        localStorage.setItem("rememberedemail", email);
        localStorage.setItem("rememberedphone", updatedPhone);
        localStorage.setItem("rememberedpassword", password);
      }

      setIsLoading(true);
      await axios
        .request(config)
        .then((res) => {
          let resData = res.data.data;
          let resStatus = res.status === 200;
          let resSuccess = res.data.success;
          let resUserRole = res.data.data.user.role;

          encryptData(resData);
          localStorage.setItem("superAdminrefresh_token", res.data.refreshToken);
          if (resUserRole === "superAdmin") {
            navigate("/superadmin/dashboard");
          } else {
            tostifyErr("You do not have access to this resource  ");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Login Error", error);
          tostifyErr(error?.response?.data?.message);
        });
    }
  };

  const tostifyErr = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };


  //phone--+919770330933
  //password--Chetan@7
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${lgnBackPic})` }}
        className="bg-cover bg-center h-screen"
      >
        <Tostify />

        <div className="container mx-auto h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-5/12">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white border-0 pl-12 pr-12 pt-8 pb-12">
                <div className="btn-wrapper text-center">
                  <div className="text-xl text-[#2b2f32] font-bold ">
                    Welcome Back
                  </div>
                </div>

                <div className="flex-auto mt-2">
                  <form onSubmit={handleLogin}>
                    {/*<div className="relative w-full mb-3">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        E-Mail
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <input
                        value={email}
                        onChange={(e) => {
                          setEmailErr(false);
                          setEmail(e.target.value);
                        }}
                        type="text"
                        className={
                          emailErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092]  bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092]  bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        }
                        placeholder="ibar@gmail.com"
                      />
                      </div>*/}

                    <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Mobile Number
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="flex gap-10 sm:gap-5 md:gap-4">
                      <div className="w-[20%] ">
                        <select
                          name="Country code"
                          id="Conutry code"
                          value={country}
                          onChange={(e) => {
                            setCountryErr(false);
                            setCountry(e.target.value);
                          }}
                          className={
                            contryErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-[78%]">
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneErr(false);
                          }}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone No."
                        />
                      </div>
                    </div>
                  </div>

                    <div className="relative w-full mb-5">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Password
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <input
                        value={password}
                        onChange={(e) => {
                          setPasswordErr(false);
                          setPassword(e.target.value);
                        }}
                        type={visible ? "text" : "password"}
                        className={
                          passwordErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  pr-10"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                        }
                        placeholder="Password"
                      />
                      {visible ? (
                        <div className="absolute right-1 top-12 ">
                          <IoMdEye
                            size={20}
                            onClick={handleClick}
                            className="mx-2 text-[#828282]"
                          />
                        </div>
                      ) : (
                        <div className="absolute right-1 top-12">
                          <IoMdEyeOff
                            size={20}
                            onClick={handleClick}
                            className="mx-2 text-[#828282]"
                          />
                        </div>
                      )}
                    </div>

                    <div className="inline-flex items-center cursor-pointer">
                      <input
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        type="checkbox"
                        className="border-[#132c4a] rounded h-5 w-5 mr-2"
                      />

                      <span className="text-[#132c4a] block text-sm">
                        Remember me
                      </span>
                    </div>

                    <div className="w-full flex items-center justify-center mt-5">
                      <button
                        onClick={handleLogin}
                        className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-[#fe8740] hover:bg-[#f95e01] transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                      >
                        {isLoading ? (
                          <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                        ) : (
                          <span>Log In</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
