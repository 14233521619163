import React, { useState, useEffect } from "react";
import WelcomeCard from "../../Components/SuperAdmin/Dashboard/WelComeCard";
import HeaderCards from "../../Components/SuperAdmin/Dashboard/HeaderCards";
import { ReviewChartCards } from "../../Components/SuperAdmin/Dashboard/ReviewChartCard";
import { fetchAdmin, fetchBranch, fetchManager, fetchStaff } from "../../FetchDataMethods/data";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [managers, setManagers] = useState([]);
  const [staff, setStaff] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const [adminData, managerData, branchData, staffData] = await Promise.all([
        fetchAdmin(),
        fetchManager(),
        fetchBranch(),
        fetchStaff(),
      ]);

      setAdmins(adminData);
      setManagers(managerData);
      setBranches(branchData);
      setStaff(staffData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <WelcomeCard />
      <HeaderCards isLoading={isLoading} admins={admins} managers={managers} staff={staff} branches={branches} />
      {/* <ReviewChartCards isLoading={isLoading} admins={admins} managers={managers} staff={staff} branches={branches} /> */}
    </>
  );
};

export default Dashboard;
