import React from 'react';
import EditSubscription from './EditSubscription';

const SubscriptionTableRow = ({ subscription, index, currentPage, itemsPerPage, fetchSubscriptions }) => (
    <tr>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{(currentPage * itemsPerPage + index) - 4}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.subscriptionId?.subscriptionName}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.organizationId?.name}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{subscription?.adminId?.userId?.firstName}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{new Date(subscription?.expiredAt)?.toLocaleDateString()}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">{new Date(subscription?.createdAt)?.toLocaleDateString()}</td>
        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
            <EditSubscription subscriptionHistoryId={subscription?._id} fetchSubscriptions={fetchSubscriptions} />
        </td>
    </tr>
);

export default SubscriptionTableRow;
