import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SubscriptionTable from '../../Components/SuperAdmin/Subscriptions/SubscriptionTable';
import Pagination from '../Common/Pagination';
import SearchBar from '../Common/SearchBar';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const totalPages = Math.ceil(filteredSubscriptions.length / itemsPerPage);

  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscription-history/get-subscription-history`);
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching subscriptions:", err);
      setError("Failed to load subscriptions.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const results = subscriptions.filter(subscription =>
      subscription?.subscriptionId?._id?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      subscription?.organizationId?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      subscription?.adminId?.firstName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredSubscriptions(results);
    setCurrentPage(1);
  }, [searchTerm, subscriptions]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubscriptions = filteredSubscriptions?.slice(indexOfFirstItem, indexOfLastItem);

  if (loading) {
    return (<div className="mx-auto mt-4 shadow rounded-lg overflow-x-auto">
      <table className="min-w-full leading-normal">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">#</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Subscription Name</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Organization</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Admin</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Expiry Date</th>
            <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {[...Array(5)].map((_, index) => (
              <td key={index} className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <div className="flex flex-row items-center">
                  <div className="animate-pulse h-2 w-1/2 flex items-center text-sm font-semibold bg-skeletonLoaderColor rounded"></div>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>)
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <h1 className='my-4 font-semibold '>Subscription History</h1>
      <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
      <SubscriptionTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        subscriptions={currentSubscriptions}
        fetchSubscriptions={fetchSubscriptions}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        indexOfFirstItem={indexOfFirstItem}
        itemsPerPage={itemsPerPage}
        totalItems={filteredSubscriptions.length}
      />
    </>
  );
};

export default Subscriptions;
