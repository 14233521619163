import React from 'react'
import CreatePackage from '../../Components/SuperAdmin/Packages/PackageModal/CreatePackage'
import PackageList from '../../Components/SuperAdmin/Packages/PackageList'

const Packages = () => {
    return (
        <>
            <div className='flex justify-between my-4'>
                <h1>Packages</h1>
                <CreatePackage />
            </div>

            <PackageList />
        </>
    )
}

export default Packages