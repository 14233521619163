// Layout.js
import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex bg-[#fdf2ec] min-h-screen ">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`flex-1 transform transition-transform duration-300 ${isSidebarOpen ? 'ml-[17rem] mr-[15px]' : 'mx-[15px]'
          }`}
      >
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="p-4 ">
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
