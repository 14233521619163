import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange, indexOfFirstItem, itemsPerPage, totalItems }) => {
    return (
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
                Showing {indexOfFirstItem + 1} to {Math.min(indexOfFirstItem + itemsPerPage, totalItems)} of {totalItems} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`text-sm ${currentPage === 1
                        ? 'bg-gray-200'
                        : 'bg-gray-300 hover:bg-gray-400'
                        } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                >
                    Prev
                </button>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`text-sm ${currentPage === totalPages
                        ? 'bg-gray-200'
                        : 'bg-gray-300 hover:bg-gray-400'
                        } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default Pagination;
