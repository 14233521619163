import React from 'react'

const Discounts = () => {
  return (
    <div>
      Discounts
    </div>
  )
}

export default Discounts
