import React from "react";
import { Navigate } from "react-router-dom";

const AuthLayout = (props) => {
  if (!localStorage.getItem("encryptedSuperAdminData")) {
    return <Navigate to={{ pathname: "superadmin/login" }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthLayout;
