import React, { useEffect, useState } from "react";
import profile_pic from "../../Assets/user.png";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BiSolidUserCircle } from "react-icons/bi";
import MenuItem from "@mui/material/MenuItem";
import { FaSortDown } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AdminProfileDropDown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  let navigate = useNavigate();
  const token = localStorage.getItem("superAdminrefresh_token");
  const decoded = token ? jwtDecode(token) : null;

  useEffect(() => {
    if (!decoded) {
      handleLogout();
      return;
    }

    const expirationTime = decoded.exp * 1000;
    const currentTime = new Date().getTime();

    if (currentTime >= expirationTime) {
      handleLogout();
      return;
    }

    const timer = setTimeout(() => {
      handleLogout();
    }, expirationTime - currentTime);

    return () => clearTimeout(timer);
  }, [decoded]);

  const profileDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    profileDropdownClose();
    localStorage.removeItem("superAdminrefresh_token");
    localStorage.removeItem("encryptedSuperAdminData");
    navigate("/logout");
  };

  const handleProfile = () => {
    profileDropdownClose();
    navigate(`/superadmin/profile`);
  };

  return (
    <div>
      <div
        onClick={profileDropdown}
        className="flex items-center justify-center transition ease-in-out hover:text-primaryColor hover:duration-300"
      >
        <div
          className={`w-10 h-10 rounded-full overflow-hidden border-2 border-gray-400 transition ease-in-out hover:border-primaryColor hover:duration-300`}
        >
          <img
            src={profile_pic}
            alt="Profile Pic"
            className="w-full h-full object-cover"
          />
        </div>

        <FaSortDown size={20} className="mb-2" />
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={profileDropdownClose}
      >
      <MenuItem onClick={handleProfile}>
      <BiSolidUserCircle size={22} className="mr-2" />
      Profile
    </MenuItem>

        <MenuItem onClick={handleLogout}>
          <RiLogoutCircleRLine size={20} color="red" className="mr-2" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminProfileDropDown;
