import React, { useEffect, useState } from "react";
import salon_pic from "../../../Assets/Beauty-salon-amico.svg";

const WelcomeCard = () => {
  const now = new Date();
  const hours = now.getHours();
  const [greeting, setGreeting] = useState("");
  const currentDate = new Date().toDateString()?.slice(4,15);
  const today = new Date();
  const dayName = today.toLocaleDateString('en-US', { weekday: 'long' });

  useEffect(() => {
    if (hours >= 5 && hours < 12) {
      setGreeting("Good morning!");
    } else if (hours >= 12 && hours < 18) {
      setGreeting("Good afternoon!");
    } else {
      setGreeting("Good evening!");
    }
  }, []);
 
  return (
    <React.Fragment>
      <div className="bg-gradient-to-tr from-[#ffbc96] via-[#ffdcc9]  to-[#ffbc96] w-full rounded-lg shadow mt-4">
        <div className="flex justify-between">
          <div className="tracking-wide px-16 my-auto h-full">
            <div className="font-bold text-2xl mt-3">{greeting} Super Admin</div>

            <div className="text-md mt-2">
              Here is what happening your store today.
            </div>

            <div className="flex items-center mt-5 mb-2.5">
              <div className="font-semibold text-md border-r border-black pr-4">
                <div className="text-[#7f8296] text-center">Today's Date</div>

                <div className="text-center mt-1">
                  {currentDate}
                </div>
              </div>

              <div className="font-semibold text-md pl-4">
                <div className="text-[#7f8296] text-center">
                  Day
                </div>
                <div className="text-center mt-1">{dayName}</div>
              </div>
            </div>
          </div>

          <div className="h-full my-auto px-6 py-2">
            <img
              className="h-56 w-80 hidden md:block"
              src={salon_pic}
              alt="salon-pic"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeCard;
