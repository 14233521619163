import React from 'react'
import ManagerTable from '../../Components/SuperAdmin/Manager/ManagerTable'

const Manager = () => {
  return (
    <React.Fragment>
    <div className="font-bold text-2xl mt-4 tracking-wide w-full">Managers</div>

    <ManagerTable />
  </React.Fragment>
  )
}

export default Manager
