import React from 'react';
import { ImSearch } from 'react-icons/im';

const SearchBar = ({ searchTerm, handleSearch }) => (
    <div className="bg-white rounded shadow w-52 flex p-1 relative">
        <input
            className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
            placeholder="Search subscriptions..."
            type="text"
            onChange={handleSearch}
            value={searchTerm}
        />
        <button
            className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
        >
            <ImSearch color="white" />
        </button>
    </div>
);

export default SearchBar;
