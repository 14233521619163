import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidDashboard } from "react-icons/bi";
import { FaUserShield } from "react-icons/fa6";
import { FaExclamationTriangle } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { TbPackages } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { MdOutlineSubscriptions } from "react-icons/md";

const SidebarsContent = () => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [setselectedItem, setSetselectedItem] = useState(location.pathname);

  // useEffect(() => {
  //   console.log("pageName", params?.dashboard)
  // }, [])

  const classes = {
    active:
      "flex items-center block px-4 py-3 mt-2 text-md font-semibold tracking-wide text-white bg-primaryColor hover:bg-primaryHoverColor rounded-lg focus:outline-none focus:shadow-outline transition ease-in-out duration-300 transform hover:translate-x-2 cursor-pointer",
    inactive:
      "flex items-center block px-4 py-3 mt-2 text-md font-semibold tracking-wide text-[#7f8296] bg-transparent rounded-lg hover:text-gray-900 hover:bg-[#febc95] transition ease-in-out duration-300 transform hover:translate-x-2 focus:outline-none focus:shadow-outline cursor-pointer",
  };

  const clickToSelect = (pageName) => {
    navigate(pageName);
    setSetselectedItem(pageName);
  };

  return (
    <div>
      <div
        className={
          setselectedItem === "/superadmin/dashboard"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/dashboard")}
      >
        <BiSolidDashboard size={24} />
        <span className="ml-2">Dashboard</span>
      </div>

      {/*<div
        className={
          setselectedItem === "/superadmin/branches"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/branches")}
      >
        <ImHome size={24} />
        <span className="ml-2">Branch</span>
      </div>*/}

      <div
        className={
          setselectedItem === "/superadmin/admins"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/admins")}
      >
        <RiAdminFill size={24} />
        <span className="ml-2">Admin</span>
      </div>

      <div
        className={
          setselectedItem === "/superadmin/packages"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/packages")}
      >
        <TbPackages size={24} />
        <span className="ml-2">Packages</span>
      </div>

      <div
        className={
          setselectedItem === "/superadmin/subscriptions"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/subscriptions")}
      >
        <MdOutlineSubscriptions size={24} />
        <span className="ml-2">Subscriptions</span>
      </div>

      <div
        className={
          setselectedItem === "/superadmin/issue"
            ? classes.active
            : classes.inactive
        }
        onClick={() => clickToSelect("/superadmin/issue")}
      >
        <FaExclamationTriangle size={24} />
        <span className="ml-2">Issue History</span>
      </div>


    </div>
  );
};

export default SidebarsContent;
